<template>
 <router-view></router-view>
</template>

<script setup>
import { watch} from 'vue'
import Cookie from "js-cookie";
import router from "@/router";
import useUserActive from "@/composables/useUserActive";
const {isExpire}= useUserActive()
watch(isExpire, (x) => {
  if(x === true){
    Cookie.remove('jwt')
    router.push("/login")
  }
})


</script>
