import store from "@/state/store";
import Cookie from "js-cookie";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Hotel Search",
      authRequired: true,
    },
    component: () => import("../views/landing/index.vue"),
  },
  {
    path: "/bookings/hotel",
    name: "booking",
    meta: { title: "Bookings", authRequired: true },
    component: () => import("../views/booking/hotel"),
  },
  {
    path: "/profile",
    name: "profile2",
    meta: { title: "My Profile", authRequired: true },
    component: () => import("../views/account/my-profile"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: { title: "Reset Password", authRequired: true },
    component: () => import("../views/account/reset-password.vue"),
  },
  {
    path: "/change-password",
    name: "change-password",
    meta: { title: "Change Password", authRequired: false },
    component: () => import("../views/account/change-password.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        Cookie.remove('jwt')
        next();
      },
    },
    component: () => import("../views/account/logout/basic")
  },


  {
    path: "/pages/maintenance",
    name: "maintenance",
    meta: { title: "Maintanance", authRequired: true },
    component: () => import("../views/pages/maintenance"),
  },
  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: { title: "Comming Soon", authRequired: true },
    component: () => import("../views/pages/coming-soon"),
  },




  {
    path: '/hotel/result',
    name: 'hotel-result',
    meta: {
      title: "Hotel Result", authRequired: true,
    },
    component: () => import('../views/hotel/result')
  },

  {
    path: '/hotel/booking',
    name: 'hotel-booking',
    meta: {
      title: "Hotel Booking", authRequired: true,
    },
    component: () => import('../views/hotel/booking')
  }


];
