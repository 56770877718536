import {createApp} from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import moment from "moment";

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import '@/assets/css/style.css';

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
store.dispatch("country/fetchCountries")
AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});
const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(PerfectScrollbar)
    .use(i18n)
    .use(vClickOutside);

app.config.globalProperties.filters = {
    formatMoney(value, currency) {
        currency = currency !== null ? currency.trim() : currency
        currency = (currency === '' || currency === null || currency === undefined) ? 'EUR' : currency
        const locale = currency === "USD" ? "en-US" : "en-GB"
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency
        })
        .format(value)
        .replace(/^(\D+)/, '$1 ')
        .replace(/\s+/, ' ');
    },
    formatNumber(value, locale) {
        return new Intl.NumberFormat(locale)
            .format(value)
    },
    truncate(value, limit) {
        try {
            return value.length > limit ? value.slice(0,(limit - 3)) + "..." : value
        }catch (e) {
            return value
        }
    },
    dateFormat(date, formatType) {
        moment.locale(i18n.global.locale.value)
        return date !== null ? moment(date).format(formatType) : date
    },
    avatarFromName(name){
        let result = "?"
        if(name !== null && name !== ""){
            let array = name.split(" ")
            result = array[0].charAt(0)
            result = array.length > 1 ? result + array[array.length-1].charAt(0) : result
        }
        return result
    },
};

app.mount('#app');
