import axios from "axios";
import Cookie from "js-cookie";
export const state = {
    countries: JSON.stringify([]),
};

export const getters = {
    countries: state => JSON.parse(state.countries),
};

export const mutations = {
    setCountries(state, newValue) {
        state.countries = newValue
    },
    pushCountries(state, newValue) {
        state.countries.push(newValue)
    },
};

export const actions = {
    fetchCountries({ commit }) {
        if(Cookie.get('jwt')){
            axios.defaults.headers.common['authorization'] = 'Bearer ' + Cookie.get('jwt');
            axios.get(process.env.VUE_APP_BASEURL + '/api/countries').then(res => {
                //commit('setTodos', res.data)
                let countries = []
                res.data.countries.forEach( (country) => {
                    let img
                    try {
                        img = require("@/assets/images/flags/" + country.codeIso2Country.toLowerCase() + ".svg")
                    } catch (e) {
                        img = require("@/assets/images/flags/mq.svg")
                    }
                    countries.push({
                        id: country.id,
                        flag: img,
                        name: country.name,
                        codeIso2Country: country.codeIso2Country,
                        phonePrefix: country.phonePrefix})
                    /*commit("pushCountries",{
                        id: country.id,
                        flag: img,
                        name: country.name,
                        codeIso2Country: country.codeIso2Country,
                        phonePrefix: country.phonePrefix})*/
                })
                commit("setCountries",JSON.stringify(countries))
            })
        }
    },
};
