import axios from "axios";
import Cookie from "js-cookie";

export const state = {
    currencies: JSON.stringify([]),
};

export const getters = {
    currencies: state => JSON.parse(state.currencies),
};

export const mutations = {
    setCurrencies(state, newValue) {
        state.currencies = JSON.stringify(newValue)
    },
    pushCurrencies(state, newValue) {
        let currencies = JSON.parse(state.currencies)
        currencies.push(newValue)
        state.currencies = JSON.stringify(currencies)
    },
};

export const actions = {
    fetchCurrencies({ commit }) {
        if(Cookie.get('jwt')){
            axios.defaults.headers.common['authorization'] = 'Bearer ' + Cookie.get('jwt');
            axios.get(process.env.VUE_APP_BASEURL + '/api/currencies').then(res => {
                commit('setCurrencies', res.data.currencies)
            })
        }
    },
};
