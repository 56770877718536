export const state = {
    languages: [
        {
            name:"English",
            country: "United Kingdom",
            code: "en",
            flag: require('@/assets/images/flags/gb.svg')
        },
        {
            name:"Russian",
            country: "Russian",
            code: "ru",
            flag: require('@/assets/images/flags/ru.svg')
        },
        {
            name:"German",
            country: "German",
            code: "de",
            flag: require('@/assets/images/flags/de.svg')
        },
        {
            name:"Spanish",
            country: "Spanish",
            code: "es",
            flag: require('@/assets/images/flags/es.svg')
        },
        {
            name:"French",
            country: "France",
            code: "fr",
            flag: require('@/assets/images/flags/fr.svg')
        },
        /*
        {
            name:"Malay",
            country: "Malay",
            code: "my",
            flag: require('@/assets/images/flags/my.svg')
        },
        {
            name:"Indonesia",
            country: "Indonesia",
            code: "id",
            flag: require('@/assets/images/flags/id.svg')
        },

        {
            name:"Arabic",
            country: "Arabic",
            code: "ar",
            flag: require('@/assets/images/flags/sa.svg')
        },


        {
            name:"Türkçe",
            country: "Turkey",
            code: "tr",
            flag: require('@/assets/images/flags/tr.svg')
        }*/
    ],
};

export const getters = {
    languages: state => state.languages,
};

