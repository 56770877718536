import axios from "axios";
import _ from "lodash"
import moment from "moment";
import Cookie from "js-cookie";
//import Hotels from "@/assets/hotels.json"

const rooms = JSON.parse(localStorage.getItem('rooms'));
let startDate = moment.utc().add(2, 'days').format('YYYY-MM-DD')
let endDate = moment.utc().add(4, 'days').format('YYYY-MM-DD')
if(localStorage.getItem('startDate') !== null){
    if(moment(JSON.parse(localStorage.getItem('startDate'))).diff(moment.utc(), 'days') > 0){
        startDate = moment(JSON.parse(localStorage.getItem('startDate'))).format('YYYY-MM-DD')
    }
}
if(localStorage.getItem('endDate') !== null){
    if(moment(JSON.parse(localStorage.getItem('endDate'))).diff(moment.utc(), 'days') > 0){
        if(moment(startDate).diff(moment(JSON.parse(localStorage.getItem('endDate'))), 'days') < 0){
            endDate = moment(JSON.parse(localStorage.getItem('endDate'))).format('YYYY-MM-DD')
        }else{
            endDate = moment(startDate).add(2, 'days').format('YYYY-MM-DD')
        }
    }
}
const hotels = (localStorage.getItem('hotels') !== null && localStorage.getItem('hotels') !== "undefined") ? JSON.parse(localStorage.getItem('hotels')) : []
const nationality = (localStorage.getItem('nationality') !== null && localStorage.getItem('nationality') !== "undefined") ? JSON.parse(localStorage.getItem('nationality')) : "United Kingdom"
let currency = "EUR"
if(JSON.parse(localStorage.getItem('currency')) !== null){
    if ((JSON.parse(localStorage.getItem('currency')) === "EUR") ||
        (JSON.parse(localStorage.getItem('currency')) === "USD") ||
        (JSON.parse(localStorage.getItem('currency')) === "GBP") ){
        currency = JSON.parse(localStorage.getItem('currency'))
    }
}
export const state = () => ({
    hotelPlace: null,
    rooms: rooms !== null ? rooms : [{
        adult:2,
        child:0,
        age:[]
    }],
    nationality,
    startDateTwo:null,
    endDateTwo: null,
    maxRoom: 5,
    maxAdult: 10,
    maxChild: 4,
    hotels,
    hotelOptions:[],
    startDate,
    endDate,
    flatPickrDate:"",
    items:JSON.stringify([]),
    filteredItems:JSON.stringify([]),
    pagedItems: JSON.stringify([]),
    sortBy: "L2H",
    currency,
    hotelSearchResult:false,
    feed: "21214"
})
export const getters = {
    hotelPlace(state){
        return state.hotelPlace
    },
    rooms(state){return state.rooms},
    nationality(state){ return state.nationality},
    startDateTwo(state){return state.startDateTwo},
    endDateTwo(state){return state.endDateTwo},
    maxRoom(state){return state.maxRoom},
    maxAdult(state){return state.maxAdult},
    maxChild(state){return state.maxChild},

    getHotels(state){return state.hotels},
    getStartDate(state){return state.startDate},
    getEndDate(state){return state.endDate},
    getFlatPickrDate(state){

        let langCookie = Cookie.get('lang')
        if(langCookie === undefined ||
            langCookie === ''
        ){
            langCookie = process.env.VUE_APP_I18N_LOCALE
        }
        let separator = langCookie === 'en' ? " to " : " - "

        if(state.flatPickrDate === ""){
            //return state.startDate + " to " + state.endDate
            return state.startDate + separator + state.endDate
        }else{
            return state.flatPickrDate
        }
    },

    getFilteredItems(state){return JSON.parse(state.filteredItems)},
    getPagedItems(state){return JSON.parse(state.pagedItems)},
    getItems(state){return JSON.parse(state.items)},
    getCurrency(state){return state.currency},
    getFeed(state){return state.feed},
    getHotelSearchResult(state){return state.hotelSearchResult},
    getSortBy(state){return state.sortBy},
}
export const mutations = {
    setHotelPlace(state, value) {state.hotelPlace = value},
    setStartDate(state,value){state.startDate = value},
    setEndDate(state,value){state.endDate = value},
    setStartDateTwo(state,value){state.startDateTwo = value},
    setEndDateTwo(state,value){state.endDateTwo = value},
    setNationality(state, value) {
        state.nationality = value
        localStorage.setItem("nationality", JSON.stringify(state.nationality))
    },
    setCurrency(state, value) {
        state.currency = value
        localStorage.setItem("currency",JSON.stringify(state.currency))
    },
    setFeed(state, value) {state.feed = value},

    changePassengerCount(state,payload){
        state.rooms[payload.index][payload.type] = payload.value
        if(payload.type === "child" && payload.value > state.rooms[payload.index].age.length){
            state.rooms[payload.index].age.push(0)
        } else if(payload.type === "child" && payload.value < state.rooms[payload.index].age.length) {
            state.rooms[payload.index].age.splice(payload.value, (state.rooms[payload.index].age.length - payload.value))
        }
        localStorage.setItem("rooms",JSON.stringify(state.rooms))
    },
    changeChildAge(state,payload){
        state.rooms[payload.roomIndex].age[payload.ageIndex] = parseInt(payload.value)
        localStorage.setItem("rooms",JSON.stringify(state.rooms))
    },
    addRoom(state){
      state.rooms.push({
          adult:2,
          child:0,
          age:[]
      })
        localStorage.setItem("rooms",JSON.stringify(state.rooms))
    },
    deleteRoom(state,payload){
        state.rooms.splice(payload,1)
        localStorage.setItem("rooms",JSON.stringify(state.rooms))
    },

    setHotels(state, value) {
        state.hotels = value
        localStorage.setItem("hotels",JSON.stringify(state.hotels))
    },

    searchedPushHotels(state, value) {
        state.hotels.push(value)
        localStorage.setItem("hotels",JSON.stringify(state.hotels))
    },
    deleteHotel(state, value){
        state.hotels.splice(value,1)
        localStorage.setItem("hotels",JSON.stringify(state.hotels))
    },
    clearAllHotels(state){
        state.hotels = []
        localStorage.setItem("hotels",JSON.stringify(state.hotels))
    },
    setFlatPickrDate(state, value) {
        if(value.length === 10){
            state.startDate = value
            state.endDate = value
        }else{
            state.startDate = value.slice(0,10)
            let dateLength = value.length

            state.endDate = value.slice(dateLength-10,dateLength)
        }
        localStorage.setItem("startDate",JSON.stringify(state.startDate))
        localStorage.setItem("endDate",JSON.stringify(state.endDate))
        state.flatPickrDate = value
    },
    setItems(state, value) {
        state.items = JSON.stringify(value)
        state.filteredItems = JSON.stringify(value)
    },

    saveHotels (state, items) { //eslint-disable-line
        //state.items = items;
    },
    pushHotels (state, items) {
        let tmpItems = JSON.parse(state.pagedItems)
        tmpItems.push(...items)
        state.pagedItems = JSON.stringify(tmpItems);
    },
    saveFilteredHotels (state, items) {
        state.filteredItems = JSON.stringify(items);
    },
    clearPagedHotel (state) {
        state.pagedItems = JSON.stringify([]);
    },
    sortHotels (state) {
        state.pagedItems = [];
        if(state.sortBy === "L2H"){
            state.filteredItems = JSON.stringify(_.orderBy(
                JSON.parse(state.filteredItems),
                "netPrice",
                "asc"
            ));
            state.items = JSON.stringify(_.orderBy(
                JSON.parse(state.items),
                "netPrice",
                "asc"
            ));
        }else if(state.sortBy === "H2L"){
            state.filteredItems = JSON.stringify(_.orderBy(
                JSON.parse(state.filteredItems),
                "netPrice",
                "desc"
            ));
            state.items = JSON.stringify(_.orderBy(
                JSON.parse(state.items),
                "netPrice",
                "desc"
            ));
        }else if(state.sortBy === "125"){
            state.filteredItems = JSON.stringify(_.orderBy(
                JSON.parse(state.filteredItems),
                "starRating",
                "asc"
            ));
            state.items = JSON.stringify(_.orderBy(
                JSON.parse(state.items),
                "starRating",
                "asc"
            ));
        }else if(state.sortBy === "521"){
            state.filteredItems = JSON.stringify(_.orderBy(
                JSON.parse(state.filteredItems),
                "starRating",
                "desc"
            ));
            state.items = JSON.stringify(_.orderBy(
                JSON.parse(state.items),
                "starRating",
                "desc"
            ));
        }else if(state.sortBy === "C2F"){
            state.filteredItems = _.orderBy(
                state.filteredItems,
                "distance",
                "asc"
            );
            state.items = _.orderBy(
                state.items,
                "distance",
                "asc"
            );
        }else if(state.sortBy === "F2C"){
            state.filteredItems = _.orderBy(
                state.filteredItems,
                "distance",
                "desc"
            );
            state.items = _.orderBy(
                state.items,
                "distance",
                "desc"
            );
        }
        const tmpFilteredItems = JSON.parse(state.filteredItems)
        let tmpPagedItems = []
        tmpPagedItems = _.drop(tmpFilteredItems, 0).slice(0, 50)
        state.pagedItems = JSON.stringify(tmpPagedItems);
    },
    saveSortBy (state, sortBy) {
        state.sortBy = sortBy;
    },
    saveOptions (state, items) {
        state.hotelOptions = items;
    },
    setHotelSearchResult(state,value){state.hotelSearchResult = value},
    setQueryRoom(state,value){
        state.rooms = value
        localStorage.setItem("rooms",JSON.stringify(state.rooms))
    }
}

export const actions = {
    hotelStorage(vuexContext,payload){
        vuexContext.commit("setHotelPlace",payload)
    },
    setStartDate(vuexContext,payload){
        vuexContext.commit("setStartDate",payload)
    },
    setEndDate(vuexContext,payload){
        vuexContext.commit("setEndDate",payload)
    },
    setStartDateTwo(vuexContext,payload){
        vuexContext.commit("setStartDateTwo",payload)
    },
    setEndDateTwo(vuexContext,payload){
        vuexContext.commit("setEndDateTwo",payload)
    },
    nationalityAdd2State(vuexContext,value){
        vuexContext.commit("setNationality",value)
    },
    searchHotels(vuexContent){
        let hotelCodes = []
        vuexContent.state.hotels.forEach((hotel) => {
            hotelCodes.push(hotel.vervotech_id)
        })
        let rooms = []
        vuexContent.state.rooms.forEach((room)=>{
            let paxes = []
            for(let i = 0; room.adult > i; i++){
                paxes.push({age:30})
            }
            for(let k = 0; room.child > k; k++){
                paxes.push({age:room.age[k]})
            }
            rooms.push({"paxes":paxes})
        })
        let nationality = vuexContent.rootGetters['country/countries'].find(item => item.name === vuexContent.state.nationality)
        vuexContent.commit("setHotelSearchResult",false)
        //http://127.0.0.1:8000/api/b2bapi
        return axios.post(process.env.VUE_APP_HUBURL + '/api/api-search',{
            "checkIn": vuexContent.state.startDate,
            "checkOut": vuexContent.state.endDate,
            "currency":vuexContent.state.currency,
            "market":"US",
            "language":"en",
            nationality: nationality !== undefined ? nationality.codeIso2Country : null,
            rooms,
            "hotels":hotelCodes.toString(),
            //"access": vuexContent.state.feed,
            "access": vuexContent.state.feed
        }).then(response => {

            let hotelList = [], orderPrice = []
            if(Object.prototype.hasOwnProperty.call(response.data, "results")){
                if(response.data.results.length > 0) {
                    orderPrice = _.orderBy(response.data.results, ['price.net'], 'asc');
                }
                orderPrice.forEach((room) => {
                    if (hotelList.length === 0 || hotelList.find(item => item.hotelCode === room.hotelCode) === undefined){
                        //let hotelInfo = Hotels.find(hItem => hItem.code === room.hotelCode)
                        let hotelInfo = vuexContent.state.hotels.find(hItem => hItem.vervotech_id == room.hotelCode)
                        //console.log("hotel-info", hotelInfo, room.hotelCode, vuexContent.state.hotels)
                        let latitude = hotelInfo.latitude !== null ? hotelInfo.latitude.replace(",", ".") : 0;
                        let longitude = hotelInfo.longitude !== null ? hotelInfo.longitude.replace(",", "."): 0;
                        latitude = !isNaN(parseFloat(latitude)) ? parseFloat(latitude) : 0
                        longitude = !isNaN(parseFloat(longitude)) ? parseFloat(longitude) : 0
                        hotelList.push({
                            "code": hotelInfo.vervotech_id,
                            "name": hotelInfo.hotel_name,
                            "country": hotelInfo.country,
                            "address": hotelInfo.address,
                            latitude,
                            longitude,
                            "phone": hotelInfo.telephone,
                            "starCode": !isNaN(parseInt(hotelInfo.category_code)) ? parseInt(hotelInfo.category_code[0]) : 0,
                            "starRating": !isNaN(parseInt(hotelInfo.category_code)) ? parseInt(hotelInfo.category_code[0]) : 0,
                            "cityCode": hotelInfo.city_code,
                            netPrice: room.price.net,
                            "currency": room.price.currency,
                            "cityName": hotelInfo.city,
                            "zipCode": hotelInfo.zipcode,
                            "startDate": vuexContent.state.startDate,
                            "endDate": vuexContent.state.endDate,
                            hotelCode: room.hotelCode,
                            filteredOption: room,
                            options: [room]
                        })
                    }else{

                        if(hotelList[hotelList.findIndex(item => item.hotelCode === room.hotelCode)].options.length < 50){
                            hotelList[hotelList.findIndex(item => item.hotelCode === room.hotelCode)].options.push(room)
                        }
                    }
                });
                vuexContent.commit('setItems',hotelList)
                return {
                    success:true,
                    message:''
                }
            } else{
                return {
                    success:false,
                    message:response.data.data.hotelX.search.warnings[0].description
                }
            }


        })
    },

    addHotels2State ({ commit }, hotels) {
        commit('saveHotels', hotels)
    },
    pushHotels2State ({ commit }, hotels) {
        commit('pushHotels', hotels)
    },
    addHotels2TempState ({ commit }, hotels) {
        commit('saveFilteredHotels', hotels)
    },
    clearPagedHotel ({ commit }, hotels) {
        commit('clearPagedHotel', hotels)
    },
    sortHotels ({ commit }) {
        commit('sortHotels')
    },
    setSortBy ({ commit }, sortBy) {
        commit('saveSortBy', sortBy)
    },

}


