import {ref,onMounted,onUnmounted} from "vue";
import Cookie from "js-cookie";
export default function useUserActive(){
    const interval = ref(0)
    const time=ref(null)
    const isExpire=ref(false)
    const eventsArr=['mousemove','keydown','scroll','click']

    const handleEvent=()=>{
        if(time.value){
            clearTimeout(time.value)
        }
        localStorage.setItem('_expiredTime',Date.now() + 3600 * 1000)



    }
    onMounted(()=>{
        eventsArr.forEach((event)=>{
            window.addEventListener(event,handleEvent)
        })
        interval.value = setInterval(() => {

            const expiredTime = parseInt(
                localStorage.getItem("_expiredTime") || 0,
                10
            );
            if (expiredTime < Date.now() && Cookie.get('jwt') !== undefined) {
                isExpire.value= true
                cleanUp()

            }
        }, 1000);
    })
    onUnmounted(()=>{
        eventsArr.forEach((event)=>{
            window.removeEventListener(event,handleEvent)
        })
    })
    const cleanUp = () => {
        localStorage.removeItem("_expiredTime");
        //console.log("TEST222")
        eventsArr.forEach((event)=>{
            window.removeEventListener(event,handleEvent)
        })
        clearInterval(interval.value);
    }
    return {isExpire}
}